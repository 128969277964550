'use client';
/**
 * LatestUpates currently copies code from here
 * A refactor would be needed to be able to extract common logic from this and LatestUpates
 */
import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import { Folder, Item, Tab } from '../../types';
import { TABS } from '../../constants';
import { Listing } from '../common';
import Breadcrumbs from '../Breadcrumbs';
import ItemInList from '../ItemInList';
import FolderInList from '../FolderInList';
import { useTranslations } from 'next-intl';
import { BreadcrumbsProps } from 'src/uiTypes';
import { notFound } from 'next/navigation';
import { useIsOffline } from 'src/hooks/useIsOffline';

const PreviewNotice = styled.p`
  color: darkred;
`;

const Intros = styled.div`
  margin-bottom: 2em;
`;

const DescriptionToggle = styled.button`
  background: none;
  color: #000;
  font-weight: 700;
  border: 0;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
`;

function ListView({
  tab,
  folderSlug,
  folderId = null,
  hasEditPrivilege,
  loadedFolder,
  loadedItems,
  foldersForTab = [],
  visibleFoldersForTab = [],
}: {
  tab: Tab;
  folderSlug?: string;
  folderId?: string | null;
  hasEditPrivilege: boolean;
  loadedFolder?: Folder | null;
  loadedItems: Array<Item> | null;
  foldersForTab: Array<Folder>;
  visibleFoldersForTab: Array<Folder>;
}) {
  const folderDoesNotExist = folderSlug && !loadedFolder;
  // Parent folder exists and is public
  const isFolderPublic =
    loadedFolder &&
    loadedFolder.parent &&
    visibleFoldersForTab.some(
      (f) => f.id === (loadedFolder && loadedFolder.parent),
    );

  const parentFolderIsNotPublic =
    !hasEditPrivilege && loadedFolder && loadedFolder.parent && !isFolderPublic;

  const folders = foldersForTab.filter((folder) =>
    folderId ? folder.parent === folderId : !folder.parent,
  );

  // We only show items if there are no sub-folders (this behaviour matches the mobile app)
  const noSubfolders = folderId && folders.length === 0;
  const items = noSubfolders ? loadedItems : null;

  const exists = !folderDoesNotExist && !parentFolderIsNotPublic;
  const isPreview =
    !!loadedFolder &&
    ((loadedFolder?.visibility && loadedFolder?.visibility !== 'public') ||
      (!!loadedFolder.parent && !isFolderPublic));

  const breadcrumbsProps: BreadcrumbsProps | null =
    folderDoesNotExist || parentFolderIsNotPublic
      ? null
      : { tab, folderId, folders: foldersForTab };
  const t = useTranslations();
  const { isOffline } = useIsOffline();
  const [isDescriptionVisible, setIsDescriptionVisible] =
    useState<boolean>(false);

  const toggleDescription = useCallback(() => {
    setIsDescriptionVisible(!isDescriptionVisible);
  }, [isDescriptionVisible]);

  if (isOffline && !folders) {
    return <p>{t('listView.error.offline')}</p>;
  }

  if (!exists || !tab) {
    notFound();
  }

  let intro;
  let description;
  if (loadedFolder) {
    intro = loadedFolder?.intro;
    description = loadedFolder?.description;
  } else {
    const translatedTabIntro = t(
      `breadcrumbs.${TABS[tab].i18nKey}.description`,
    );
    intro = `<p>${translatedTabIntro}</p>`;
  }
  const toggleText = isDescriptionVisible
    ? t('listView.hideDescription')
    : t('listView.showDescription');

  return (
    <>
      {breadcrumbsProps && <Breadcrumbs {...breadcrumbsProps} />}
      <h1>
        {loadedFolder
          ? loadedFolder.title
          : t(`breadcrumbs.${TABS[tab].i18nKey}.name`)}
      </h1>
      <Intros>
        {intro && <div dangerouslySetInnerHTML={{ __html: intro }} />}
        {description && isDescriptionVisible && (
          <div dangerouslySetInnerHTML={{ __html: description }} />
        )}
        {description && (
          <DescriptionToggle onClick={toggleDescription}>
            {toggleText}
          </DescriptionToggle>
        )}
      </Intros>
      {isPreview && (
        <PreviewNotice>
          <span role="img" aria-label={t('listView.noticeLabel')}>
            ⚠️
          </span>
          {t('listView.noticeContentTeam')}
        </PreviewNotice>
      )}
      <Listing>
        {folders &&
          folders.length > 0 &&
          folders.map((folder) => (
            <FolderInList key={folder.id} folder={folder} />
          ))}
        {items &&
          items.length > 0 &&
          items.map((item) => (
            <ItemInList key={item.id} item={item} showBreadcrumbs={false} />
          ))}
      </Listing>
    </>
  );
}

export default ListView;
